import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import {
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { GatsbyImageFocused } from '@/features/common-blocks'
import { useElementHeight } from '@/hooks/useElementRect'
import { useWindowWidth } from '@/hooks/useWindowDimensions'
import { absoluteFill, bezier, scrim } from '@/theme/mixins'
import { breakpoints, colors } from '@/theme/variables'

import { MediaCarouselLayout } from './MediaCarousel'

interface Props extends ComponentPropsWithoutRef<'figure'> {
  data?: Queries.MediaCarouselImageFragment | null
  carouselHeight: number | null
  setCarouselHeight?: Dispatch<SetStateAction<number | null>>
  layout: MediaCarouselLayout
}

const MediaCarouselImage = ({
  data,
  carouselHeight,
  setCarouselHeight,
  layout,
  ...props
}: Props): JSX.Element => {
  const { image } = data || {}
  const { inView, ref: inViewRef } = useInView({
    rootMargin: '20% -50%',
  })
  const [heightRef, setCarouselHeightRef] =
    useState<HTMLElement | null>(null)
  const height = useElementHeight(heightRef)

  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (setCarouselHeight && inView && height) {
      setCarouselHeight(height)
    }
  }, [inView, height, setCarouselHeight])

  const getImageData = () => {
    if (windowWidth && windowWidth > breakpoints.ms) {
      switch (layout) {
        case 'ARTICLE':
          return image?.uncropped
        case 'HIGHLIGHT':
          return image?.mobile
      }
    } else {
      return image?.mobile
    }
  }

  const getImageAspectRatio = () => {
    if (windowWidth && windowWidth > breakpoints.ms) {
      switch (layout) {
        case 'ARTICLE':
          return undefined
        case 'HIGHLIGHT':
          return 4 / 3
      }
    } else {
      return 4 / 3
    }
  }

  const styles = {
    figure: css`
      width: 100%;
      position: relative;
      margin: var(--border-thickness) 0;
      align-self: flex-start;
      --background-color: #fff;
      ${layout === 'ARTICLE' &&
      css`
        &:before {
          content: '';
          position: absolute;
          top: calc(-1 * var(--border-thickness));
          left: calc(-1 * var(--border-thickness));
          width: calc(100% + 2 * var(--border-thickness));
          height: calc(100% + 2 * var(--border-thickness));
          background: var(--background-color);
        }
        &:after {
          content: '';
          position: absolute;
          height: var(--border-thickness);
          width: 100%;
          background: var(--background-color);
          top: ${carouselHeight &&
          height &&
          Math.min(carouselHeight, height)}px;
          left: 0;
          transition: top 750ms ease;
        }
      `}
      ${layout === 'HIGHLIGHT' &&
      css`
        display: grid;
        height: 100%;
      `}
    `,
    opacityWrapper: css`
      position: relative;
      opacity: 0.125;
      transition: opacity 500ms ${bezier.easeOut};
      pointer-events: none;
      display: grid;
      ${inView &&
      css`
        opacity: 1;
        transition-duration: 1000ms;
        pointer-events: all;
        &:after {
          opacity: 0;
        }
      `}
      ${layout === 'HIGHLIGHT' &&
      css`
        display: grid;
      `}
    `,
    image: css`
      grid-area: 1 / 1 / 2 / 2;
    `,
    figcaption: css`
      position: relative;
      font-size: var(--fs-15);
      font-style: italic;
      color: ${colors.gray40};
      margin: 1rem 0 0;
      max-width: 108ch;
      transition: opacity 300ms ease;
      opacity: ${inView ? 1 : 0};
      ${layout === 'HIGHLIGHT' &&
      css`
        grid-area: 1 / 1 / 2 / 2;
        align-self: flex-end;
        padding: 4em 1em 2rem calc(9.25rem + 1em);
        position: relative;
        > span {
          position: relative;
          color: #fff;
        }
        &:before {
          content: '';
          ${absoluteFill};
          background: ${scrim('#000', 0.75, 'to top')};
        }
      `}
    `,
  }

  return (
    <figure
      css={styles.figure}
      ref={node => {
        setCarouselHeightRef(node)
        inViewRef(node)
      }}
      {...props}
    >
      <div css={styles.opacityWrapper}>
        {windowWidth && (
          <GatsbyImageFocused
            image={getImageData()}
            aspectRatio={getImageAspectRatio()}
            data={image}
            css={styles.image}
          />
        )}
        {image?.title && (
          <figcaption css={styles.figcaption}>
            <span>{image.title}</span>
          </figcaption>
        )}
      </div>
    </figure>
  )
}

export const MediaCarouselImageFragment = graphql`
  fragment MediaCarouselImage on DatoCmsImageBlock {
    __typename
    id: originalId
    image {
      uncropped: gatsbyImageData(width: 1280)
      wide: gatsbyImageData(
        width: 1280
        imgixParams: { ar: "3:2", fit: "crop", crop: "focalpoint" }
      )
      mobile: gatsbyImageData(
        width: 720
        imgixParams: {
          q: 75
          ar: "4:3"
          fit: "crop"
          crop: "focalpoint"
        }
      )
      title
      alt
      width
      height
      ...ImageFocalData
    }
  }
`

export default MediaCarouselImage
